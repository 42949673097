$color: #00A3A8;
.button {
  font-size: 12px;
  color: $color;
  font-family: 'roboto regular', sans-serif;
  cursor: pointer;
  user-select: none;
  &.disabled {
    color: #b3b3b3;
    cursor: not-allowed;
  }
}
