.root {
  position: relative;
  
  .loading {
    inset: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.738);
    color: #ffd000;
  }
}
