.action_box_container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 175px;
  height: 35.98px;
  white-space: nowrap;

  & .base_header {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    gap: 11.5px;
    padding-inline: 32.5px 11px;

    .title_header {
      color: #ffffff !important;
    }
    
    &:hover {
      background-color: #006729 !important;
      border: none;
    }
  }

  & .header {
    background-color: #ffffff;
    border: 1px solid #1a1446;
  }

  & .title_header {
    font-size: 16px;
    color: #000000 !important;
    font-family: 'hdipro medium', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    text-align: center;
  }

  & .arrow_dark {
    height: 5px;
    width: 10px;
  }

  & .disable {
    background-color: #e6e6e6 !important;
    border: #e6e6e6 !important;
    box-shadow: none !important;
    cursor: not-allowed !important;

    &:hover {
      background-color: #e6e6e6 !important;

      .title_header {
        color: #565656 !important;
      }
    }

    .title_header {
      text-align: center;
      color: #565656 !important;
    }

    .arrow_dark {
      display: none;
    }
  }

  & .open {
    background-color: #006729 !important;
    border: none;

    .title_header {
      color: #ffffff !important;
    }
  }

  & .options_box {
    position: absolute;
    flex-direction: column;
    top: calc(100% + 1px);
    right: 0;
    min-width: 100%;
    width: max-content;
    max-height: 289.37px;
    background-color: #ffffff !important;
    z-index: 100;

    @media (max-width: 1024px) {
      position: absolute;
      z-index: 1;
    }

    & .option {
      padding-inline: 10px;
      width: 100%;
      height: 45.27px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color: #E5F5EC;
        font-weight: bold;
      }

      & .option_text {
        font-size: 13px;
        color: #000000;
        font-family: 'roboto regular', sans-serif;
      }
    }

    & .disable {
      background-color: #e6e6e6 !important;
      border: #e6e6e6 !important;
      box-shadow: none !important;
      cursor: not-allowed !important;

      & .option_text {
        color: #565656;
        font-family: 'roboto regular', sans-serif;
      }

      &:hover {
        background-color: #e6e6e6 !important;
      }
    }
  }
}
.action_box_container_second {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 175px;
  height: 35.98px;
  white-space: nowrap;
  @media (max-width: 1152px) {
    width: 286px !important;
  }

  & .base_header {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    gap: 11.5px;
    padding-inline: 32.5px 11px;
    &:hover {
      background-color: #006729 !important;
      border: none;

      .title_header {
        color: #ffffff !important;
      }
    }
  }

  & .header {
    background-color: #ffffff;
    border: 1px solid #65A518;
  }

  & .title_header {
    font-size: 16px;
    color: #000000 !important;
    font-family: 'hdipro medium', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    text-align: center;
  }

  & .arrow_dark {
    height: 5px;
    width: 10px;
  }

  & .disable {
    background-color: #e6e6e6 !important;
    border: #e6e6e6 !important;
    box-shadow: none !important;
    cursor: not-allowed !important;

    &:hover {
      background-color: #e6e6e6 !important;

      .title_header {
        color: #565656 !important;
        font-family: 'hdipro medium', sans-serif;
      }
    }

    .title_header {
      text-align: center;
      color: #565656 !important;
      font-family: 'hdipro medium', sans-serif;
    }

    .arrow_dark {
      display: none;
    }
  }

  & .open {
    background-color: #006729 !important;
    border: none;

    .title_header {
      color: #ffffff !important;
    }
  }

  & .options_box {
    position: absolute;
    flex-direction: column;
    top: calc(100% + 1px);
    right: 0;
    min-width: 100%;
    width: max-content;
    max-height: 289.37px;
    background-color: #ffffff !important;
    box-shadow: 0px 2px 4px rgba(52, 55, 65, 0.2509803922);
    z-index: 100;

    @media (max-width: 1024px) {
      position: absolute;
      z-index: 1;
    }

    & .option {
      padding-inline: 10px;
      width: 100%;
      height: 45.27px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color: #E5F5EC;
        font-weight: bold;
      }

      & .option_text {
        font-size: 13px;
        color: #000000;
        font-family: 'roboto regular', sans-serif;
      }
    }

    & .disable {
      background-color: #e6e6e6 !important;
      border: #e6e6e6 !important;
      box-shadow: none !important;
      cursor: not-allowed !important;

      & .option_text {
        color: #565656;
        font-family: 'roboto regular', sans-serif;
      }

      &:hover {
        background-color: #e6e6e6 !important;
      }
    }
  }
}
.container_spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 10px;
  border: 1px solid #65A518;

  & .spinner_loader {
    position: relative;
    width: 30px;
    height: 30px;

    &::before,
    &::after {
      animation: spin 2s linear infinite;
      content: '';
      border: 2px solid black;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      width: 90%;
      height: 90%;
    }
  }

  & .spinner_loader::after {
    animation-delay: 1s !important;
  }

  &:hover {
    background-color: #e6e6e6 !important;
    cursor: no-drop;
  }
}

@keyframes spin {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}
