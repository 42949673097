.root {
  margin-top: 8px;
}
.icon {
  width: 13px;
  height: 13px;
  margin-top: 3px;
}
.title {
  font-size: 9px;
  font-family: 'roboto medium', sans-serif;
  margin-left: 5px;
}
.titleCount {
  font-size: 10px;
  font-family: 'roboto medium', sans-serif;
}
.item {
  padding: 4px;
}
.dateRoot {
  display: flex;
  margin-top: 2px;
  margin-left: 5px;
  .date {
    color: #919191;
    font-size: 8px;
    margin-bottom: 4px;
  }
}

.line {
  background-color: lightcoral;
  border: 2px solid;
  border-radius: 2px 0px 0px 2px;
  height: 100%;
  display: block;
  &.green {
    border-color: #03ac63;
    background-color: #03ac63;
  }
  &.red {
    border-color: #d32f2f;
    background-color: #d32f2f;
  }
}

.icon_close {
  width: 8px;
  height: 8px;
  //margin-top: 10px;
  margin-right: 8px;
  margin-left: 12px;
  cursor: pointer;
}
