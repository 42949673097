.container {
    margin-left: 21px;
    display: flex;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    height: max-content;
    width: max-content;
  
    & input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  
    & .checkmark {
      position: relative;
      height: 18px;
      width: 18px;
      border: 1px solid #919191;
      border-radius: 3px;
      background-color: transparent;
    }
  
    input:checked ~ .checkmark {
      background-color: transparent;
    }
  
    input:checked ~ .checkmark:after {
      display: block;
    }
  
    & .checkmark:after {
      left: 5px;
      bottom: 4px;
      width: 6px;
      height: 12px;
      border: solid black;
      border-width: 0 1px 1px 0;
      -webkit-transform: rotate(40deg);
      -ms-transform: rotate(40deg);
      transform: rotate(40deg);
      content: '';
      position: absolute;
      display: none;
    }
  
    input:not(:checked) ~ .blocked {
      border: 1px solid #c0bfc0 !important;
      background-color:  transparent !important;
      cursor: not-allowed;
    }
  
    input:checked ~ .blocked {
      border:none !important;
      background-color:  #c0bfc0!important;
      cursor: not-allowed;
    }
  }
  