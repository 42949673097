.content_input {
  position: relative;
  margin-inline: auto;

  & input {
    visibility: hidden;
    position: absolute;
  }

  & .circle {
    border: 2px solid #C0BFC0;
    background-color: #ffffff;
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2.67px;
  }

  & .circle::before {
    content: '';
    height: 100%;
    width: 100%;
    border-radius: 100%;
    position: relative;
    z-index: 1;
    background: #565656;
    transition: all 0.25s ease; /* Todas las propiedades | tiempo | tipo movimiento */
    opacity: 0; /* Lo ocultamos*/
  }

  & input[type='radio']:checked + .circle {
    border: 2px solid #009C49;
    background-color: transparent;
  }

  & input[type='radio']:checked + .circle::before {
    opacity: 1;
    border: none !important;
  }
}
