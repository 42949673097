//* Standard options
$background_input: #ffffff;
$border_input: 1px solid #919191;
$border_input_hover: 1px solid #009C49;
$border_input_focus: 2px solid #009C49;
$border_radius_input: 3px;
$font_family_input: 'roboto light';
$font_size_input: 16px;
$color_input: #707070;

//* Disable options
$background_input_disable: #e6e6e6;
$border_input_disable: none;
$border_input_hover_disable: none;
$border_input_focus_disable: none;
$color_input_disable: #707070;

//* Warning options
$border_input_warning: 1px solid #b85d00;
$border_input_hover_warning: 1px solid #b85d00;
$border_input_focus_warning: 2px solid #b85d00;

//* Error options
$background_input_error: #fff4f5;
$border_input_error: 1px solid #e92243;
$border_input_hover_error: 1px solid #e92243;
$border_input_focus_error: 2px solid #e92243;
$color_input_error: #e92243;

.search_icon {
  display: flex;
  height: 100%;
  width: max-content;
  margin-left: 7px;
  position: relative;
  align-items: center;
  & img {
    margin-block: auto;
    height: 25px;
  }
}

.liberty_form {
  position: relative;
  height: 100%;
  background-color: $background_input;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;

  & .error_msg {
    display: none;
  }

  & .input {
    outline: none;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: $border_input;
    border-radius: $border_radius_input !important;
    font-family: $font_family_input, sans-serif;
    font-size: $font_size_input;
    color: $color_input;
    padding: 15px 0 2px 14px;
    background: none;

    &:hover {
      border: $border_input_hover !important;
    }

    &:focus {
      border: $border_input_focus !important;
    }
  }

  & .placeholder {
    z-index: 0;
    position: absolute;
    color: $color_input;
    top: 50%;
    left: 7px;
    transform: translate(0, -50%);
    height: max-content;
    margin-block: auto;
    cursor: text;
    transition: top 200ms ease-in, font-size 200ms ease-in, margin-top 200ms ease-in;
  }
}

.liberty_disable_form {
  background-color: $background_input_disable !important;

  & .input {
    border: $border_input_disable !important;
    color: $color_input_disable !important;

    &:hover {
      border: $border_input_hover_disable !important;
    }
    &:focus {
      border: $border_input_focus_disable !important;
    }
  }
}

.liberty_warning_form {
  & .input {
    border: $border_input_warning !important;

    &:hover {
      border: $border_input_hover_warning !important;
    }

    &:focus {
      border: $border_input_focus_warning !important;
    }
  }
}

.liberty_error_form {
  background-color: $background_input_error;
  overflow: visible;
  padding: 10px;

  & .error_msg {
    position: absolute;
    display: flex;
    left: 0;
    font-size: 12px;
    color: $color_input_error;
    height: max-content;
    width: 100% !important;
    z-index: 1;

    & .container_error_icon {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      height: 100%;

      & img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
    }

    & span {
      overflow: hidden;
      white-space: normal;
    }
  }

  & .input {
    border: $border_input_error !important;

    &:hover {
      border: $border_input_hover_error !important;
    }

    &:focus {
      border: $border_input_focus_error !important;
    }
  }
}

.inactive {
  & .input:hover {
    border: none !important;
  }
}

.input:focus ~ .placeholder,
.input:not(:placeholder-shown).input:not(:focus) ~ .placeholder {
  top: 10px;
  margin-top: 0;
  font-size: 12px;
}
