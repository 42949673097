.container_search_box {
  background-color: #ffffff;
  position: relative;
  border: 1px solid #919191;
  border-radius: 3px;

  &:hover {
    border: 1px solid #009C49;
  }

  & .header {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-inline: 7px 12.05px;

    & .placeholder {
      position: absolute;
      display: flex;
      font-family: 'roboto light', sans-serif !important;
      color: #707070;
      height: max-content;
      width: max-content;
      transition: font-size 0.5s ease 0s;
    }

    & .placeholder_up {
      font-size: 12px;
      top: 5.5px;
      z-index: 1;
    }

    & .placeholder_down {
      font-size: 16px;
      position: relative !important;
    }

    & .input_search {
      background-color: transparent;
      outline: none;
      border: none;
      width: 100%;
      height: 100%;
      font-size: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-top: 10px;
      color: rgb(52, 55, 65);
    }

    & .container_arrow {
      margin-left: auto;
    }
  }

  & .options_box {
    z-index: 1001;
    position: absolute;
    transition: height 0.3s ease;
    top: 105%;
    width: 100%;
    max-height: 273.36px;
    background-color: #ffffff;
    overflow-y: auto;
    box-shadow: 0px 2px 4px rgb(52 55 65 / 25%);
    list-style-type: none;
    padding: 0;
    margin: 0;

    & .option {
      background-color: transparent;
      padding-left: 20px;
      min-height: 45px;
      font-size: 13px;
      text-align: start;
      display: flex;
      align-items: center;
      font-family: 'roboto regular', sans-serif;
      cursor: pointer;

      &:hover {
        background-color: #F2FAF5;
      }
    }
  }
}
.active {
  background-color: #F2FAF5 !important;
}

.disable {
  cursor: not-allowed !important;
  background-color: #e6e6e6 !important;
  border: #e6e6e6 !important;
  box-shadow: none !important;

  .container_arrow {
    display: none;
  }
}
