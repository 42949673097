.alert_download_complete {
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  gap: 8.01px;
  box-shadow: 0px 2px 4px #00000080;
  border: 1px solid #e6e6e6;
  border-left: 5px solid #03ac63;
  border-radius: 3px;
  min-height: 107px;
  width: 344px;
  padding: 18px 40px 16px 12px;

  & .icon_good {
    height: 15.99px;
    width: 15.99px;
  }

  & .close {
    cursor: pointer;
    opacity: 0.5;
    margin-left: auto;
    height: 11.94px;
    width: 11.94px;
  }

  & .info {
    display: flex;
    flex-direction: column;

    & .title {
      height: 19px;
      width: 164px;
      font-size: 16px;
      font-family: 'roboto bold', sans-serif;
      color: #008040;
    }

    & .sub_title {
      width: 250px;
      font-size: 14px;
      font-family: 'roboto regular', sans-serif;
      color: #343741;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      outline: none;
      margin-top: 11px;
    }
  }
}
