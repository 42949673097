.notification {
  display: flex;
  height: 84px;
  width: 100%;
  padding-inline: 15.14px;
  margin-inline: auto;

  &__icon {
    width: 29.69px;
    margin-bottom: auto;
    margin-top: 18.84px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    & .title {
      font-size: 12px;
      font-family: 'roboto medium', sans-serif;
      color: #343741;
      min-width: 157px;
      width: max-content;
      margin-top: 18.48px;
    }
    & .date {
      font-size: 10px;
      font-family: 'roboto regular', sans-serif;
      color: #9c9c9c;
      width: max-content;
      margin-top: 1px;
    }
    & .document_download {
      width: 307px;
      height: 31px;
      display: flex;
      flex-direction: row;

      &.process__icon {
        height: 19px;
        width: 22.55px;
        & img {
          height: 100%;
          width: 100%;
        }
      }

      &.process__icon_error {
        height: 19px;
        width: 22.55px;
        & img {
          height: 100%;
          width: 100%;
        }
      }

      & .document_content {
        width: 130px;
        height: 30px;
        display: flex;
        flex-direction: column;

        & .document_title {
          font-size: 11px;
          font-family: 'roboto medium', sans-serif;
        }
        & .document_action {
          cursor: pointer;
          font-size: 12px;
          font-family: 'roboto medium', sans-serif;
          color: #00A3A8;
        }
      }
    }
    & .process {
      height: 19px;
      width: max-content;
      margin-top: 7.5px;
      display: flex;
      &__icon {
        height: 19px;
        width: 22.55px;
        & img {
          height: 100%;
          width: 100%;
        }
      }

      &__icon_error {
        margin-inline: 5.03px 5.02px;
      }

      &__action {
        cursor: pointer;
        height: 19px;
        font-size: 14px;
        font-family: 'roboto medium', sans-serif;
        color: #00A3A8;
      }
    }
  }

  &__quantity {
    display: flex;
    height: 16px;
    flex: auto;
    font-size: 12px;
    font-family: 'roboto medium', sans-serif;
    margin: 17.84px 11.9px auto 31.42px;
  }

  &__icon_close {
    width: 8px;
    height: 8px;
    margin-top: 10px;
    margin-right: 10px;
    opacity: 0.7;
    cursor: pointer;
  }
}
