.alert_download_complete {
  transition-property: opacity, z-index;
  transition-duration: 0.5s;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #00000080;
  border: 1px solid #e6e6e6;
  border-left: 5px solid #03ac63;
  border-radius: 3px;
  height: 117.49px;
  min-width: 344px;
  position: relative;
  z-index: 999 !important;
  @media (min-width: 1152px) {
    display: none !important;
  }

  & .icon {
    margin: 16px 8.01px 30px 11.83px;
    & img {
      height: 15.99px;
      width: 15.99px;
    }
  }

  & .close {
    margin-left: auto;
    margin-top: 16px;
    margin-right: 16px;
    cursor: pointer;
    opacity: 0.5;
    & img {
      height: 11.94px;
      width: 11.94px;
    }
  }

  & .info {
    display: flex;
    flex-direction: column;

    & .title {
      height: 19px;
      width: 164px;
      font-size: 16px;
      font-family: 'roboto bold', sans-serif;
      color: #008040;
      margin-top: 15px;
    }

    & .sub_title {
      height: 17px;
      width: 170px;
      font-size: 14px;
      font-family: 'roboto regular', sans-serif;
      color: #343741;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      outline: none;
      margin-top: 11px;
    }
  }
}
