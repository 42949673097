.container {
  margin-left: 21px;
  display: flex;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  height: max-content;
  width: max-content;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  & .checkmark {
    position: relative;
    height: 15.75px;
    width: 15.75px;
    border: 1px solid #009C49;
    border-radius: 3px;
    background-color: transparent;
  }

  input:checked ~ .checkmark {
    background-color: #009C49;
    border: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  & .checkmark:after {
    left: 5px;
    bottom: 4px;
    width: 4px;
    height: 9.5px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg);
    content: '';
    position: absolute;
    display: none;
  }

  input:not(:checked) ~ .blocked {
    border: 1px solid #c0bfc0 !important;
    background-color:  transparent !important;
    cursor: not-allowed;
  }

  input:checked ~ .blocked {
    border:none !important;
    background-color:  #c0bfc0!important;
    cursor: not-allowed;
  }
}
