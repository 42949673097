$primary: #FCFCFC;

.liberty__container {
  margin: 0;
  height: max-content;
  position: relative;
  width: 100vw;
}

.libertyHeader {
  background-color: $primary;
  width: 100%;
  height: 104px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #1a1446;

  .logoBackground {
    margin-right: auto;
    width: 153px;
    height: 104px;
    margin-left: 45px;
    background-color: #FFFFFF;
    justify-content: center;
  }

  & .logo {
    margin-right: auto;
    width: max-content;
    margin-left: 55px;
    margin-top: 30px;
  }

  & .liberty_actions_header {
    display: flex;
    max-width: 1152px;
    width: 100%;
    margin-inline: auto;
  }

  & .profile {
    gap: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  & .username {
    font-size: 14px;
    line-height: 17px;
    font-weight: normal;
    width: max-content;
    font-family: 'hdipro medium';
    color: #6E6E6E;
  }

  & .icon_desktop {
    all: unset;
    width: 33px;
    height: 33px;
    background-color: white;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    position: relative;
     @media (max-width:1152px){
    display: none !important;
  }
  }
}

/* Estilos para mobile */
@media (max-width: 1152px) {
 
   .liberty_actions_header .profile .username {
    display: none;
  }
  .liberty_actions_header .profile .img {
    display: none;
  }
   .liberty_actions_header .profile .Button {
    display: none;
  }

}