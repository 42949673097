.root {
  padding-top: 67px !important;
  background-color: #ffffff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0px 5px 6px -5px #00000040;
  margin-top: 1.5px;
  height: 140px;

  @media (max-width: 1152px) {
    flex-direction: column;
    width: 100%;
    padding-top: 67px !important;
    height: 184px;
  }

  .actions {
    max-width: 1152px;
    width: 100%;
    margin-inline: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .buttons {
      margin: 0 0 27px 0;
      gap: 16px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  .title {
    color: #65A518;
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
    font-family: 'hdipro medium', sans-serif;
  }
}

@media (max-width: 1152px) {
  .root {
    .actions {
      flex-direction: column;
      justify-content: flex-end;
      max-width: 1152px;
      width: 100%;
      display: flex;
      margin-inline: auto;

      .buttons {
        flex-direction: row;
        justify-content: end;
        align-items: center;
        width: 100%;
        height: 19px;
        padding: 20px;
      }
    }
    .title {
      margin-right: 10px;
      font-size: 24px;
      line-height: 28px;
      margin-left: 5%;
    }
  }
}
