.root {
  position: relative;
  color: #1a1446;
  opacity: 0.6;
  font-size: 35.5px;
  font-family: 'roboto medium', sans-serif;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
