.tooltip {
  display: none;
  width: max-content;
  height: max-content;
  position: absolute;
  z-index: 1000;

  & .triangle_down {
    width: 0;
    height: 0;
    border-top: 10px solid #003960 !important;
    border-left: 12px solid transparent !important;
    border-right: 10px solid transparent !important;
  }

  & .triangle_up {
    width: 0;
    height: 0;
    border-bottom: 10px solid #003960 !important;
    border-left: 12px solid transparent !important;
    border-right: 10px solid transparent !important;
  }

  & .triangle_right {
    width: 0;
    height: 0;
    transform: translateX(-1px);
    border-bottom: 10px solid transparent !important;
    border-top: 12px solid transparent !important;
    border-left: 10px solid #003960 !important;
  }

  & .triangle_left {
    margin-block: auto;
    width: 0;
    height: 0;
    transform: translateX(1px);
    border-bottom: 10px solid transparent !important;
    border-top: 10px solid transparent !important;
    border-right: 12px solid #003960 !important;
  }

  & .info_tooltip {
    padding: 20px;
    display: flex;
    justify-content: center;
    border-radius: 3px;
    background-color: #003960;
    color: #ffffff;
    font-size: 14px;
    font-family: 'roboto regular', sans-serif;
    &:hover {
      display: none;
    }
  }
}

.top {
  flex-direction: column;
}

.bottom {
  flex-direction: column-reverse;
}

.right {
  flex-direction: row-reverse;
}

.left {
  flex-direction: row;
}
