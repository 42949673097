.liberty_bread_crumbs {
  width: 100%;
  max-width: 1152px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
@media (min-width: 1152px) {
    display: none !important;
  }
  & .title {
    cursor: pointer;
    color: #343741;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  & .arrow {
    margin: 0 5px;
  }

  @media (max-width: 1152px){
    padding-inline: 20px;
  }
}
