.title {
  padding: 11px;
  width: 100%;
  font-family: 'hdipro medium', sans-serif;
  color: #65A518;
  text-align: center;
  font-size: 32px;
}

.description{
  font-family: 'roboto medium', sans-serif;
  color: #343741;
  line-height: 24px;
  text-align: center;
  .str {
    font-family: 'roboto bold', sans-serif;
    width: 159px !important;
    height: 44px !important;
  }
}

.container {
  height: 532px !important;
  padding: 0px !important;
  .body {
    padding: 20px !important;
  }
  .footer {
    background-color: #F0F0F0;
    height: 158px;
    width: 100%;
    .buttons {
      margin: 32px;
      height: 100%;
      .button {
        height: 44px;
        width: 159px;
      }
    }
  }
}
