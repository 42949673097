.root {
  background-color: rgba(0, 103, 200, 0.92) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;

  .documentForm__documents--title {
    text-align: center;
  }

  .container {
    width: 626px;
    min-height: 477px;
    //max-width: fit-content;
    background-color: #ffffff;
    padding: 20px 20px 50px 20px;
    box-sizing: border-box !important;
    border-radius: 3px;
    gap: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     @media (max-width: 1152px) {
         width: 90%;
      }

    &.lg {
      width: 948px;
      min-height: 528px;
    }
    &.max {
      width: 948px;
      height: 729px;
    }

    &:focus-visible {
      outline: none;
    }

    &.reconsiderModal {
      position: absolute;
      top: 0;
      padding: 20px 0px;
      height: 971px;
    }

    .close {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      &.reconsiderModal {
        margin: 56px 200px 0px 0px;
      }

      & button {
        all: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
    &.clockIcon {
      display: flex;
    }
  }
}
