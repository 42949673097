.root {
  height: 65px;
  width: 76px;
  border: solid 3px #e6e6e6;
  border-radius: 5px;
  padding: 10px 10px;
  .container {
    overflow: hidden;
    height: 100%;
  }
}
