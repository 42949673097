.digitDownAnim {
  animation: digitDown 1s infinite;
}

@keyframes digitDown {
  from {
    -webkit-transform: translateY(0%);
  }
  35% {
    -webkit-transform: translateY(-100%);
  }
  to {
    -webkit-transform: translateY(-100%);
  }
}

.twoPoints {
  font-size: 40px;
  color: #1a144699;
  padding: 5px 6.5px;
  font-family: 'roboto medium', sans-serif;
}
