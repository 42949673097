$container-width: 1152px;

//*Buttons Color LibertySeacher
$bg-button: #99e5ea;
$bg-button-dissable: #e6e6e6;

//*state_box
$size_img: 50px;
$w-status: 69px;
$h-status: 19px;

$error: #d32f2f;
