.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 96px;
}
.root {
  margin-bottom: 13px;
}

.title {
  font-family: 'roboto light', sans-serif;
  font-size: 48px;
  display: flex;
  justify-content: center;
  color: #1a1446;
}
.subtitle {
  @extend .title;
  font-size: 20px;
  font-family: 'roboto bold', sans-serif !important;
  color: #343741;
  margin-top: 9px;
  font-weight: bold;
}
.description {
  @extend .title;
  margin-top: 11px;
  font-family: 'roboto medium', sans-serif;
  font-size: 14px;
  color: #28a3af;
  font-weight: bold;
}
.button {
  margin-top: 28px;
  height: 44px !important;
  width: 160px !important;
}
.line {
  text-decoration-line: overline;
  border-top: 1px solid #c0bfc0;
  margin-top: 60px;
  opacity: 1;
}

.footer {
  @extend .title;
  margin-top: 29px;
  color: #808080;
  font-size: 14px;
  font-family: 'roboto regular', sans-serif;
}
