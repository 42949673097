$background_input_error: #fff4f5;
$border_input_error: 1px solid #e92243;
$border_input_hover_error: 1px solid #e92243;
$border_input_focus_error: 2px solid #e92243;
$color_input_error: #e92243;

.container_component {
  background-color: lightgreen;
  position: relative;
  height: 100%;
  width: 100%;

  & .header {
    background-color: #ffffff;
    height: 100%;
    border: 1px solid #919191;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 10px 14.1px;
    cursor: pointer;
    z-index: 2;
    & .placeholder {
      // transition: font-size 0.2s ease 0s, top 0.2s ease 0s;
      position: absolute;
      color: #707070;
      cursor: pointer;
    }

    &:hover {
      border: 1px solid #009C49;
    }

    & .option_selected {
      font-size: 16px;
      color: #343741;
      padding-top: 10px;
    }

    & .input_new_option {
      padding-top: 10px;
      background-color: transparent;
      border: none;
      outline: none;
      height: 100%;
      width: 100%;
      font-size: 16px;
      color: #343741;
    }

    & .arrow {
      margin-left: auto;
    }
  }

  & .header_focus {
    border: 2px solid #009C49 !important;
  }

  & .error {
    background-color: $background_input_error !important;
    border: $border_input_error !important;

    label {
      color: $color_input_error !important;
    }
  }

  & .error_focus {
    background-color: $background_input_error !important;
    border: $border_input_focus_error !important;

    label {
      color: $color_input_error !important;
    }
  }
  & .container_msg_error {
    position: absolute;
    width: 100%;
    align-items: center;
    & .msg_error {
      display: flex;
      background-color: transparent;
      align-items: center;
      height: max-content;
      padding: 4px 2px;

      & .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 4px;
        margin-bottom: auto;
        height: 16px;
        width: 16px;
      }

      & .msg {
        color: $color_input_error;
        font-size: 12px;
        display: flex;
        flex-wrap: wrap;
        height: max-content;
      }
    }
  }

  & .options_box {
    transition: height 0.2s ease 0s;
    background-color: #ffffff;
    position: absolute;
    width: 100%;
    max-height: 289.37px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    box-shadow: 0px 2px 4px #34374140;
    z-index: 3;
    align-items: flex-start;

    & .option {
      cursor: pointer;
      position: relative;
      font-size: 13px;
      text-align: start;
      height: max-content;
      width: 100%;
      padding: 15px 0 15px 20px;
      display: flex;
      align-items: center;

      & .icon_option {
        margin-right: 20px;
        height: 20px;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        & img {
          height: 15.9px;
          width: 10.97px;
          opacity: 0.7;
        }
      }

      & span {
        color: #1a1446;
        font-family: 'roboto medium', sans-serif;
      }

      &:hover {
        background-color: #F2FAF5;
      }
    }
  }
}
