.alert_download_incomplete {
  background-color: #ffffff;
  display: flex;
  gap: 8.01px;
  box-shadow: 0px 2px 4px #00000080;
  border: 1px solid #e6e6e6;
  border-left: 6px solid #e92243;
  border-radius: 3px;
  min-height: 107px;
  width: 344px;
  padding: 18px 40px 16px 12px;

  & .icon_bad {
    height: 15.99px;
    width: 15.99px;
  }

  & .close {
    cursor: pointer;
    opacity: 0.5;
    color: gray;
    height: 11.94px;
    width: 11.94px;
    margin-left: auto;
  }

  & .info {
    display: flex;
    flex-direction: column;
    position: relative;

    & .title {
      height: 19px;
      width: 179px;
      font-size: 16px;
      font-family: 'roboto bold', sans-serif;
      color: #d32f2f;
    }

    & .sub_title {
      min-height: 17px;
      width: 262px;
      font-size: 16px;
      font-family: 'roboto regular', sans-serif;
      color: #343741;
      letter-spacing: 0px;
      line-height: 25px;
    }

    & .second {
      color: #06748c !important;
    }
  }
}
