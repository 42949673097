.libertyFooter {
  height: 103.5px;
  width: 100vw;
  background-color: transparent;
  border-top: 1px solid #E0E0E0;
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 300px;
  justify-content: center;

    & .item_name {
      color: #808080;
      height: 17px;
      font-family: 'hdipro medium', sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      margin-top: 15.5px;
      margin-inline: auto;
      text-align: center;
    }
  
}
