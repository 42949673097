$primary: #F2FAF5;
$border: #009C49;
$excess: 500px;

.primary {
  position: relative;

  & .warning {
    border: 1px solid #b85d00 !important;
    background: #ffffff 0% 0% no-repeat padding-box !important;
  }

  & .select {
    cursor: pointer;
    font-family: 'roboto bold', sans-serif;
    width: 100%;
    min-height: 48px;
    height: 100%;
    text-align: left;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #919191;
    border-radius: 3px;
    display: flex;
    justify-content: start;
    align-items: center;

    &:hover {
      border: 1px solid $border;
    }

    & .info_span_on {
      display: flex;
      position: absolute;
      font-size: 12px;
      top: 5.5px;
      left: 7px;
      font-family: 'roboto light', sans-serif !important;
    }

    & .info_span_off {
      display: none;
    }

    & .selected_option {
      margin: 0;
      margin-left: 7px;
      pointer-events: none;
      width: 90%;
      height: 19px;
      font-size: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
    }

    & .container_arrow {
      position: relative;
      pointer-events: none;
      margin: auto;
      margin-inline: auto 12.05px;
      & img {
        height: 10.97px;
        width: 15.95px;
      }
    }

    & .arrow_dark {
      margin: 15.5px 11px 15.5px 11.5px;
      height: 5px;
      width: 10px;
    }
  }


  & .focus {
    border: 2px solid $border !important;
  }

  & .container_options_tooltips {
    width: 100%;
    position: absolute;
    height: max-content !important;
    right: 0;
    z-index: 10000;
    display: flex;
    justify-content: flex-end;
    background-color: lightcoral;

    & .options {
      width: 100%;
      position: relative;
      background-color: transparent;
      text-align: start;
      z-index: 1001;
      max-height: 273.36px;
      height: max-content;
      overflow-y: auto;
      box-shadow: 0px 2px 4px #34374140;

      & .option {
        position: relative;
        font-size: 13px;
        text-align: start;
        margin: 0;
        width: 100%;
        border: none;
        border-radius: 0px;
        background: white;
        height: max-content;
        padding: 15px 0 15px 20px;
        cursor: pointer;

        &:hover {
          background-color: #F2FAF5;

          & .tooltip {
            display: flex;
          }
        }

        & .name_icon {
          display: flex;
          align-items: center;

          & .icon {
            margin-right: 10px;
            height: 20px;
            width: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            & img {
              height: 16px;
              width: 16px;
              opacity: 0.7;
            }
          }
        }

        & .tooltip {
          display: flex;
          position: relative;
          width: 261px;
          height: 87px;
          left: -101%;
          top: 0;
          z-index: 1000;

          & .info_tooltip {
            background-color: #1e1045;
            width: 226px;
            border-radius: 3px !important;
            & .value {
              margin: 13px 2px 12.2px 15px;
              color: white;
              font-size: 14px !important;
              font-family: 'roboto regular', sans-serif;
            }
          }

          & .triangulo_tooltip {
            position: absolute;
            z-index: 10000;
            left: 0;
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-left: 12px solid lightcoral;
            border-bottom: 10px solid transparent;
            // background-color: lightgreen;
          }
        }

        & .lastTooltip {
          top: -90%;
        }
      }
    }
  }
}

.secondary {
  position: relative;
  width: max-content;
  height: 100%;

  & .warning {
    border: 1px solid #d32f2f !important;
    background: #fff4f5 0% 0% no-repeat padding-box !important;
  }

  & .select {
    cursor: pointer;
    min-width: 175px;
    height: 100%;
    text-align: left;
    letter-spacing: 0px;
    opacity: 1;
    border: 1px solid #1a1446;
    border-radius: 3px;
    box-shadow: 0px 3px 0px #c0bfc0;
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: #ffffff !important;
    position: relative;

    &:hover {
      background-color: #06748c !important;
      border: none;
      box-shadow: 0px 3px 0px #1a1446;

      & span {
        color: #ffffff !important;
      }
      & .tooltip_container {
        display: flex;
        & span {
          color: #ffffff !important;
        }
      }
    }

    & .info_span_on {
      display: none !important;
    }

    & .info_span_off {
      display: none;
    }

    & .selected_option {
      position: relative;
      margin: auto !important;
      margin-right: 11.5px !important;
      pointer-events: none;
      width: max-content;
      height: max-content;
      font-size: 16px;
      background-color: transparent;
      color: #1a1446 !important;
      font-family: 'roboto bold', sans-serif;
    }

    & .container_arrow {
      position: relative;
      pointer-events: none !important;
      margin: 18.52px 12.05px 18.51px 0;
      height: 10.97px;
      width: 15.95px;
    }

    & .container_arrow_black {
      height: 100%;
      width: max-content;
      display: flex;
      flex-direction: column;
      justify-content: center;
      pointer-events: none !important;
      padding-right: 11px;
    }

    & .tooltip_container {
      display: none;
      color: #ffffff;
      background-color: #1a1446;
    }
  }

  & .focus {
    background-color: #06748c !important;
    border: none;
    box-shadow: 0px 3px 0px #1a1446;

    & .selected_option {
      color: #ffffff !important;
    }
  }

  & .container_options_tooltips {
    width: max-content;
    position: absolute;
    right: 0;
    z-index: 1;
    overflow: auto;
    background-color: transparent;
    justify-content: flex-end;

    & .options {
      position: relative;
      width: max-content;
      background-color: #ffffff !important;
      max-height: 289.37px;
      height: max-content;
      text-align: start;
      z-index: 100;
      box-shadow: 0px 2px 4px #34374140;

      & .disable {
        cursor: no-drop !important;
        background: #e6e6e6 !important;
        color: #565656;
      }

      & .option {
        cursor: pointer;
        position: relative;
        font-size: 13px;
        text-align: start;
        margin: 0;
        width: 100%;
        border: none;
        border-radius: 0px;
        background: transparent;
        height: max-content;
        padding: 15px 10px 15px 20px;

        &:hover {
          background-color: #F2FAF5;
          & .tooltip {
            display: flex;

            &:hover {
              display: none;
            }
          }
        }

        & .tooltip {
          display: none;
          position: absolute;
          width: max-content;
          max-height: 87px;
          right: 100%;
          top: 0;
          z-index: 1000;
          background-color: transparent;
          & .info_tooltip {
            background-color: #1e1045;
            max-width: 226px;
            margin-left: auto;
            width: max-content;
            padding: 16px 8px 16px 16px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-radius: 3px;
            & .value {
              color: white;
              font-size: 14px !important;
              font-family: 'roboto regular', sans-serif;
            }
          }

          & .triangulo_tooltip {
            position: relative;
            z-index: 10000;
            top: 10px;
            right: 0;
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-left: 12px solid #1e1045;
            border-bottom: 10px solid transparent;
            // background-color: lightgreen;
          }
        }
      }
    }

    &:hover {
      width: calc(100% + $excess) !important;
    }
  }
}

.third {
  position: relative;
  cursor: pointer;

  & .select {
    font-family: 'roboto bold', sans-serif;
    width: 100%;
    height: 100%;
    text-align: left;
    letter-spacing: 0px;
    opacity: 1;
    background-color: $primary;
    border: 1px solid $border;
    border-radius: 5px 0 0 5px !important;
    display: flex;
    align-items: center;
    &:hover {
      & .tooltip_modificaciones {
        display: flex;
      }
    }

    & .info_span_on {
      display: none;
    }

    & .info_span_off {
      display: none;
    }

    & .selected_option {
      margin-top: 15px !important;
      margin-inline: 10px auto;
      margin-bottom: 15px;
      pointer-events: none;
      width: max-content;
      height: 19px;
      font-size: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      color: #343741 !important;
    }

    & .container_arrow {
      // background-color: lightgreen;
      position: relative;
      pointer-events: none;
      margin: 18.52px 12.05px 18.51px auto;
      height: 10.97px;
      width: 15.95px;
    }

    & .arrow_dark {
      // background-color: lightgreen;
      margin: 15.5px 10px 15.5px 10px;
      height: 5px;
      width: 10px;
    }
  }

  .container_options_tooltips {
    width: 100%;
    background-color: transparent;
    position: absolute;
    right: 0;
    z-index: 1;
    overflow-y: auto;

    & .options {
      width: 100%;
      position: relative;
      background-color: #ffffff;
      max-height: 289.37px;
      height: max-content;
      padding: 0;
      margin-inline: auto 0;
      text-align: start;
      z-index: 100;
      overflow-y: auto;
      box-shadow: 0px 2px 4px #34374140;
      color: #1e1045;

      & .option {
        position: relative;
        font-size: 13px;
        text-align: start;
        margin: 0;
        width: 100%;
        border: none;
        height: max-content;
        padding: 15px 0 15px 20px;

        &:hover {
          background-color: #F2FAF5;
          & .tooltip {
            display: flex;
          }
        }

        cursor: pointer;

        & .tooltip {
          display: flex;
          position: relative;
          width: 261px;
          height: 87px;
          left: -101%;
          top: 0;
          z-index: 1000;

          & .info_tooltip {
            background-color: #1e1045;
            width: 226px;
            & .value {
              margin: 13px 2px 12.2px 15px;
              color: white;
              font-size: 14px !important;
              font-family: 'roboto regular', sans-serif;
            }
          }

          & .triangulo_tooltip {
            position: absolute;
            z-index: 10000;
            left: 0;
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-left: 12px solid lightcoral;
            border-bottom: 10px solid transparent;
            // background-color: lightgreen;
          }
        }

        & .lastTooltip {
          top: -90%;
        }
      }
    }
  }
}

.primary,
.secondary {
  & .msg_warning_on {
    display: flex;
    top: -20px;
    position: absolute;
    color: #d32f2f;
    font-size: 12px;
    align-items: center;

    & img {
      margin-right: 8px;
      max-height: 16px;
    }
  }

  & .msg_warning_off {
    display: none;
  }
}

.disable {
  & .select {
    cursor: not-allowed !important;
    background-color: #e6e6e6 !important;
    border: #e6e6e6 !important;
    box-shadow: none !important;
    & .selected_option {
      font-family: 'roboto regular', sans-serif !important;
      color: #565656 !important;
      margin-inline: auto !important;
    }

    & .with_value {
      margin-left: 7px !important;
    }

    &:hover {
      background-color: #e6e6e6 !important;

      & span {
        color: #565656 !important;
      }
    }
  }
  & .focus {
    background-color: #e6e6e6 !important;
    border: none;
    box-shadow: 0px 3px 0px #1a1446;

    & .selected_option {
      color: #ffffff !important;
    }
  }
}

.container_spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 10px;
  & .spinner_loader {
    position: relative;
    width: 30px;
    height: 30px;
    &::before,
    &::after {
      animation: spin 2s linear infinite;
      content: '';
      border: 2px solid black;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      width: 90%;
      height: 90%;
    }
  }
  & .spinner_loader::after {
    animation-delay: 1s !important;
  }

  &:hover {
    background-color: #e6e6e6 !important;
    cursor: no-drop;
  }
}

@keyframes spin {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
