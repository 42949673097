.libertyButton {
  all: unset;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  //border-radius: 3px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  cursor: pointer;
  font-family: 'hdipro medium', sans-serif;

  &__tooltip {
    position: absolute;
    right: -20px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &--container {
      min-width: 227px !important;
    }
  }

  &.primary {
    width: 175px;
    height: 36px;
    color: #FFFFFF;
    background: #65A518;
    border: 1px solid #65A518;
  }

  &.secondary {
    width: 162px;
    height: 38px;
    color: #000000;
    background-color: #ffffff;
    border: 1px solid #65A518;
  }

  &.tertiary {
    width: 175px;
    height: 36px;
    color: #ffffff;
    background-color: #009C49;
    border: 1px solid #009C49;
  }

  &.quaternary {
    width: 175px;
    height: 36px;
    color: #000000;
    background-color: #ffffff;
    border: 1px solid #65A518;
    @media (max-width: 1152px) {
      width: 286px !important;
    }
    &:hover {
      color: #ffffff;
      background-color: #006729;
    }
  }

  &:hover,
  &:active {
    color: #ffffff;
    background-color: #006729;
    border: 1px solid #006729;
  }

  &:active {
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    font-weight: normal !important;
    color: #565656;
    background-color: #e6e6e6;
    border: 1px solid #e6e6e6;
    box-shadow: none;
    cursor: not-allowed;
  }
}
