@import 'styles/variables';
@import 'styles/states/states.module.scss';


@font-face {
  font-family: 'roboto light';
  src: url('../assets/fonts/Roboto/Roboto-Light.ttf');
}

@font-face {
  font-family: 'roboto medium';
  src: url('../assets/fonts/Roboto/Roboto-Medium.ttf');
}

@font-face {
  font-family: 'roboto bold';
  src: url('../assets/fonts/Roboto/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'roboto regular';
  src: url('../assets/fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'hdipro medium';
  src: url('../assets/fonts/HDIPro/HDIPro-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: 'roboto regular', sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  // overflow-x: hidden;
}

html {
  background-color: #f5f5f5;
}

.liberty__separator {
  width: 80%;
  border: 1px solid #e0e0e0;
}

.liberty__row {
  width: 100%;
  text-align: left;
}

.liberty__row--identification {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  span {
    color: #808080;
    font-size: 11px;
    line-height: 14px;
  }
}

.liberty__row--status {
  color: #ffffff;
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  width: min-content;
  width: 70px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.liberty__row--status--uppercase {
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 11px;
  font-weight: bold;
  width: min-content;
  height: 20px;
  text-transform: uppercase;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.liberty__pagination {
  margin: 40px 0 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.liberty__header--buttons {
  gap: 9px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.liberty__menu--item {
  height: 47px;
  color: #1e1045 !important;
  font-size: 14px !important;

  &--content {
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
  }
}

.liberty__formControl {
  margin: 0 !important;
  gap: 8px;
}

.liberty__container--column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.liberty__container--row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.liberty__detail {
  &--card {
    all: unset;
    width: 100%;
    height: 68px;
    border: 1px solid #e6e6e6;
    border-left: 5px solid #99e5ea;
    border-radius: 3px;
    padding: 10px 15px;
    box-sizing: border-box !important;
    background: #ffffff;
    gap: 15px;
    display: grid;
    grid-template-columns: 38px auto repeat(5, 0.9fr) 1.2fr;
    align-items: center;
    cursor: pointer;
  }

  &--card.selected {
    background: #f0fbfc;
    border: 2px solid #28a3af;
    border-left: 5px solid #03ac63;
  }

  &--item {
    justify-content: flex-start;
    gap: 9px;
  }

  &--radio {
    width: 18px;
    height: 18px;

    &:checked {
      accent-color: #03ac63;
    }
  }

  &--icon {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    background: #f0fbfc;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--icon.selected {
    background: #ffffff;
    border: 1px solid #99e5ea;
  }

  &--title {
    color: #808080;
    font-size: 14px;
    line-height: 17px;
  }

  &--value {
    color: #343741;
    font-size: 15px;
    line-height: 18px;
    font-weight: 600;
  }
}

.input__calendar--container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.input__autocomplete--container {
  width: 100%;
  padding: 7.5px;
  box-sizing: border-box;
  position: absolute;
  top: 60px;
  z-index: 1000;
  background-color: #ffffff;
  // box-shadow: 0px 2px 6px #00000029;
  gap: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.MuiLinearProgress-root {
  height: 8px !important;
  border-radius: 4px;
}

.MuiInputBase-input {
  text-transform: capitalize !important;
}

.MuiListItem-root.Mui-focusVisible,
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover,
.liberty__menu--item:hover {
  background-color: #f0fbfc !important;
  font-weight: 600 !important;
}

.MuiTypography-body1 {
  color: #343741 !important;
}

.MuiFormControlLabel-root {
  margin: 0 !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 20px;
  background: #e6e6e6;
}

::-webkit-scrollbar-thumb {
  background: #009C49;
  border-radius: 5px;
  border: 3px solid #009C49;
}

::-webkit-scrollbar-thumb:hover {
  background: #009C49;
}

/* HELPERS */
.cursor-pointer {
  cursor: pointer !important;
}

.liberty__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.liberty__card--item {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;
  gap: 7px;

  &--title {
    color: #565656;
    font-size: 12px;
    font-weight: 500;
  }

  &--value {
    color: #1a1446;
    font-size: 12px;
    font-weight: 550;
  }

  &--subvalue {
    color: #565656;
    font-size: 11px;
    font-weight: 500;
  }
}

.libertyHelpTooltip {
  background-color: #fff4f5 !important;
  color: #343741 !important;
  white-space: nowrap !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  &__arrow {
    color: #fff4f5 !important;
  }

  &__icon {
    height: 50px;
    width: 50px;
    background-color: #e92243;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
  }
}
