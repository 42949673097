.root {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &.reconsiderModal {
    margin: 56px 200px 0px 0px;
  }
  .close {
    width: 19px;
    height: 19px;
  }

  & button {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
    }
  }
}
