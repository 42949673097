.calendar {
  transition-property: opacity z-index height;
  transition-duration: 0.3s;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  width: 320px;
  background-color: #ffffff;
  position: absolute;
  border: none;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border-radius: 3px;

  & .header_calendar {
    z-index: 10000;
    background-color: #003960;
    position: relative;
    height: 148px;
    width: 100%;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & .container_years_months {
      display: flex;
      flex-direction: column;
      padding: 20px 30px;

      & .year_calendar {
        font-size: 14px;
        font-family: 'roboto medium', sans-serif;
        opacity: 0.7;
      }

      & .day_month_calendar {
        font-size: 31px;
        font-family: 'roboto regular', sans-serif;
      }
    }

    & .buttons_container {
      background-color: transparent;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      height: 50px;

      & .header_button {
        cursor: pointer !important;
        background-color: transparent;
        color: white;
        border: none;
        font-size: 15px;
        font-family: 'roboto medium', sans-serif;
        width: 100%;
        height: 100%;
        border-bottom: 4px solid transparent;

        &:hover {
          border-bottom: 4px solid #65A518;
        }
      }
    }
  }

  & .body_calendar {
    height: 357px;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 10000;

    & .month {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;

      & span {
        cursor: default;
        font-size: 14px;
        font-family: 'roboto medium', sans-serif;
        color: #000000df;
      }

      & button {
        cursor: pointer !important;
        margin: 0;
        border: none;
        background-color: transparent;
        width: 50px;
        height: 50px;
      }

      & .btn_left {
        padding-inline: 30px 30px;
      }

      & .btn_right {
        padding-inline: 30px 30px;
      }
    }

    & .days_calendar {
      list-style: none;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      padding: 0;
      margin-bottom: 0;
      margin-inline: 20px;
    }

    & .container_buttons {
      display: flex;
      margin-inline: auto 30px;
      margin-block: auto 20px;
      width: 125px;
      height: max-content;
      justify-content: space-between;

      & button {
        cursor: pointer !important;
        margin: 0;
        background-color: transparent;
        color: #65A518;
        border: none;
        font-size: 15px;
        font-family: 'roboto medium', sans-serif;
      }
    }
  }
}

.day_name {
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: 'roboto medium', sans-serif !important;
  color: #0000008b;
  cursor: default;
}

.days {
  background-color: transparent;
  cursor: pointer;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: 'roboto medium', sans-serif;
  position: relative;

  &:hover {
    background-color: #f8f6f6;
    border-radius: 100%;
  }
}

.selected {
  background-color: #65A518 !important;
  border-radius: 100%;
  color: #ffffff !important;
  font-family: 'roboto regular', sans-serif !important;
  &:hover {
    background-color: #65A518 !important;
  }
}

.valid_date {
  color: #222222;
}

.invalid_date {
  color: #808080;
}

.paint_range {
  background-color: #e5f5ec;
  border-radius: 100%;
  color: #222222;
  font-family: 'roboto regular', sans-serif !important;
  &:hover {
    background-color: #e5f5ec !important;
    color: #222222 !important;
  }
}

.selected ~ .paint_range {
  &:hover {
    background-color: #e5f5ec !important;
  }
}
