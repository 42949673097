@import 'styles/variables';

.root {
  display: flex;
  justify-content: center;
  width: 100%;

  .container {
    max-width: $container-width;
    width: 100%;

    @media (max-width: 1180px) {
      padding: 0 20px;
    }
  }
}
