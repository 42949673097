.pagination {

  height: max-content;
  width: max-content;

  & .btn {
    cursor: pointer;
    height: 32px;
    width: 32px;
    border-radius: 3px;
    border: none;
  }

  & .btnArrows {
    cursor: pointer;
    background-color: #ffffff;
    justify-content: center;
    position: relative;
    &:disabled{
        opacity: 0.6;
    }
  }

  & .btnLeft {
    margin-right: 10px;
    & img {
      transform: rotate(180deg);
    }
  }

  & .btnRight {
    margin-left: 10px;
  }

  & .btnPages {
    background-color: #f5f5f5;
    color: #1e1045;
    margin-right: 8px;

    &:hover {
      background-color: #00A3A8;
      color: #ffffff;
    }
  }

  & .btnSelected {
    background-color: #00A3A8;
    color: #ffffff;
  }

  & .btnTotal{
    margin-right: 0 !important;
  }

  & .first {
    margin-right: 8px !important;
  }

  & .last {
    margin-right: 0px !important;
  }

  & .threePoints {
    cursor: default ;
    background-color: none !important;
    font-size: 14px;
    letter-spacing: 1.312px;
    width: 50px !important;
    height: 17px !important;
  }

  & .right_treePoints{
    margin-inline: 16px 17px;
  }

  & .left_treePoints{
    margin-inline: 17px 16px;
  }
}
