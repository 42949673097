.container_icon {
  width: 32px;
  height: 32px;
  background-color: white;
  border-radius: 100%;
  cursor: pointer;
  display: none;
  justify-content: center;
  align-items: center;
  @media (max-width: 1152px) {
    display: flex !important;
  }
  & .container_info_user {
    position: fixed;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100000;
    background-color: #ffffff;

    & .header_container {
      display: flex;
      width: 100%;
      height: 53px;
      gap: 16px;
      align-items: center;
      padding-inline: 39.92px;

      & .icon_close {
        margin-left: auto;
      }
      & .header_title {
        color: #1a1446;
        font-size: 24px;
        font-family: 'roboto medium', 'roboto regular', sans-serif;
      }
    }
    & .principal_info {
      display: flex;
      flex-direction: column;
      background: #f4f4f4;
      align-items: left;
      width: 100%;
      min-height: 145px;

      & .container_principal_info {
        margin-top: 43px;
        margin-left: 67px;
        margin-bottom: 20px;

        & .container_image_info {
          display: flex !important;
          gap: 7px;
          margin-bottom: 10px;
          & .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            background-color: white;
            border-radius: 100%;
            & img {
              width: 100%;
              height: 100%;
            }
          }

          & .container_info {
            max-width: 240px !important;
            height: max-content;
            display: flex;
            flex-direction: column;

            & .title {
              color: #06748c;
              font-size: 14px;
              font-family: 'roboto medium', 'roboto regular', sans-serif;
            }

            & .info {
              width: 100%;
              height: max-content;
              word-wrap: break-word;
              color: #1a1446;
              font-size: 16px;
              font-family: 'roboto medium', 'roboto regular', sans-serif;
            }
          }
        }
      }
    }
    & .secundary_info {
      width: 100%;
      height: max-content;
      & .container_info_session {
        display: flex;
        flex-direction: column;
        gap: 15px;

        & .container_info {
          display: flex;
          flex-direction: column;
          margin-right: 23.5px !important;
          margin-left: 23.5px !important;
          border-bottom: 1px solid #e0e0e0;
          & .title {
            margin-left: 85px;
            margin-top: 10px;
            font-size: 12px;
            color: #808080;
          }
          & .info {
            margin-top: 2px;
            font-size: 14px;
            margin-left: 85px;
            margin-bottom: 10px;
            color: #343741;
            font-family: 'roboto medium', 'roboto regular', sans-serif;
          }
        }
      }
    }
    & .button_container {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      border-top: 100px;
      width: 100%;
      height: 70px;
    }
    & .footer {
      height: 103.5px;
      background-color: transparent;
      border-top: 1px solid #e0e0e0;
      display: flex;
      flex-direction: row;
      position: absolute;
      bottom: 0px;
      left: 23.5px;
      right: 23.5px;
      justify-content: center;

      & .item_name {
        color: #808080;
        height: 17px;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        margin-top: 15.5px;
        margin-inline: auto;
      }
    }
  }
}
