.container_main {
  height: max-content;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: none;
  background-color: transparent;
  min-height: 450px;
}

.container_cards_alerts {
  display: flex;
  flex-direction: column;
  width: max-content;
  height: max-content;
  position: fixed;
  top: 100px;
  right: 35px;
  gap: 20px;
  z-index: 1000;
}
