.container_option {
  position: relative;
  min-height: 51px;
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 15px 0 15px 0px;
  z-index: 10;
  & .container_info {
    display: flex;
    align-items: center;
    color: #1E1045;
    font-size: 14px;
    & .color_option {
      height: 16px;
      width: 16px;
      margin-right: 22px;
      border-radius: 100%;
    }
  }
  &:hover {
    background-color: #F2FAF5;
  }
}


.checked{
  font-family: 'roboto medium', sans-serif !important;
}