.container_check_box {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 48px;

  & .header_check_box {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    overflow: hidden;
    gap: 8px;
    padding-inline: 10px;
    cursor: pointer;
    border: 1px solid #919191;
    border-radius: 3px;

    & .container_options_selected {
      display: flex;
      align-items: center;
      overflow: hidden;
      width: 100%;
      max-width: 90%;
      height: 100%;
      gap: 4px;

      & .container_option_selected {
        display: flex;
        white-space: nowrap;
        margin-top: 10px;

        & .color_option {
          height: 16px;
          width: 16px;
          margin-right: 2px;
          border-radius: 100%;
        }
      }
    }

    & .placeholder {
      color: #707070;
      font-family: "roboto regular", sans-serif;
      position: absolute;
      left: 5%;
      transform: translate(0%, -50%);
      transition-property: top, font-size;
      transition-duration: 0.3s;
    }

    & .container_arrow {
      margin-left: auto;

      & img {
        transition-property: transform;
        transition-duration: 0.4s;
        transition-timing-function: ease;
      }
    }

    &:hover {
      border: 1px solid #009C49 !important;
    }
  }

  & .option_box {
    transition-property: height;
    transition-duration: 0.4s;
    transition-timing-function: ease;
    background-color: #ffffff;
    position: absolute;
    top: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 255px;
    width: 100%;
    box-shadow: 0px 2px 6px #00000080;
  }

  & .focus {
    border: 2px solid #009C49 !important;
  }

  & .inactive{
    background-color: #e6e6e6 !important;
    border: none !important;
    &:hover{
      border: none !important;
    }
  }
}
