.container {
  height: 457px !important;
  padding: 0px !important;
  .body {
    padding: 20px !important;
    .grid {
      height: 84%;
      text-align: center;
      color: #65A518;
    }
  }
  .footer {
    background-color: #F0F0F0;
    height: 158px;
    width: 100%;
    .buttons {
      margin: 32px;
      height: 100%;
      .button {
        height: 44px;
        width: 159px;
      }
    }
  }
}
