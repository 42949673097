.root {
  position: relative;
  display: flex;
  flex-direction: row;
  .loading {
    inset: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.738);
    color: #ffd000;
  }
  .icon {
    width: 16px;
    height: 16px;
    margin-left: 3px;
  }
}
