.container_profile_info {
  
  &:hover {
    & .tooltip {
      display: block;
    }
  }

  & .tooltip {
    display: none;
  }


  & .tooltip_user {
    background-color: transparent;
    position: absolute;
    top: 32px;
    right: -10px;
    z-index: 101;


    & .triangle_up {
      background-color: transparent;
      margin-inline: auto 10px;
      width: max-content;
      height: 10px;
      top: 20px;

      & img {
        height: 14.76px;
        width: 32px;
        margin: 0;
        padding: 0;
        top: 10px;
      }
    }

    & .info_user_icon {
      overflow: hidden;
      background-color: white;
      min-height: 393.84px;
      height: max-content;
      width: 319.06px;
      padding-block: 5px;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 2px 6px #00000029;
      border-radius: 3px;
 

      & .title_tooltip {
        color: #006729;
        font-family: 'roboto medium', sans-serif;
        font-size: 18px;
        margin: 10.4px auto 3.55px 24.5px;
      }

      & .container_profile {
        min-height: 145px;
        background-color: #f5f5f5;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 6.57px;
        padding: 20px 0px 20px 20px;

        & .container_image_info {
          display: flex;
          gap: 7px;
        
          & .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            background-color: white;
            border-radius: 100%;
            & img {
              width: 100%;
              height: 100%;
            }
          }

          & .container_info {
            max-width: 240px !important;
            height: max-content;
            display: flex;
            flex-direction: column;
            & .title {
              color: #006729;
              font-size: 14px;
            }

            & .info {
              width: 100%;
              height: max-content;
              word-wrap: break-word;
              color: #1a1446;
              font-size: 16px;
              font-family: 'roboto medium', 'roboto regular', sans-serif;
            }
          }
        }
      }

      & .container_info_session {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 6.67px 0 30.12px 66.56px;

        & .container_info {
          display: flex;
          flex-direction: column;
      
          & .title {
            margin-top: 10px;
            font-size: 12px;
            color: #808080;
           
          }
          & .info {
            margin-top: 2px;
            font-size: 14px;
            color: #343741;
            font-family: 'roboto medium', 'roboto regular', sans-serif;
           
          }
        }
      }
    }
  }



}
