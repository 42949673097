.notifications {
  position: absolute;
  top: 37px;
  left: -272px;
  z-index: 101 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .header_tabs {
    position: relative;
    width: 374.74px;
    height: auto;
    gap: 0px;
    z-index: 100;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .title {
      color: #006729;
      font-family: 'roboto regular', sans-serif;
      font-size: 15px;
      font-weight: bold;
    }

    .tabs {
      display: flex;
      width: 350.26px;
      height: max-content;
      margin-top: 13px;
      .tab {
        height: 37.53px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: #343741;
        font-size: 12px;
        border-bottom: 2px solid #e6e6e6;
        font-family: 'roboto regular', sans-serif;

        .tab_title {
          opacity: 0.5;
          z-index: -1;
        }

        .total_ticket {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 16px;
          width: 16px;
          background-color: #e92243;
          font-size: 9px;
          color: #ffffff;
          border-radius: 100%;
          margin-left: 13.2px;
        }
      }

      .tab:hover {
        border-bottom: 4px solid #65A518;
        color: #343741;
        font-size: bold;
      }

      .active {
        color: #343741;
        border-bottom: 4px solid #65A518;
        font-family: 'roboto medium', sans-serif;

        .tab_title {
          opacity: 1;
          z-index: -1;
        }
      }
    }
  }

  .panel {
    position: relative;
    width: 374.74px;
    height: auto;
    max-height: 433.46px;
    overflow-y: auto;
    overflow-x: none;
    gap: 0px;
    z-index: 100;
    box-sizing: border-box !important;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000026;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .no_notification_container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-block: 32.29px 33.94px;

      .bell_no_notification {
        margin-left: 21.68px;
      }

      .no_notification {
        margin-right: 53.68px;
        font-size: 18px;
        color: #343741;
      }
    }

    .rootDivider {
      width: 344.46px;
      margin-inline: auto;
      .divider {
        border-bottom: 1px solid #e0e0e0;
      }
    }
  }

  .title {
    width: max-content;
    font-size: 15px;
    font-weight: 500;
    font-family: 'roboto medium', sans-serif;
    line-height: 18px;
    color: #28a3af;
    margin-inline: 24.04px auto;
    margin-top: 22.68px;
  }
  .subtitle {
    color: #28a3af;
    font-size: 15px;
    font-weight: 500;
  }
  .statePolicy {
    display: flex;
    align-items: center;
  }
  .date {
    font-family: 'roboto regular', sans-serif;
    font-size: 10px;
  }

  .triangle_up {
    background-color: transparent;
    margin-inline: auto 70px;
    width: max-content;
    height: 6px;

    & img {
      height: 18px;
      width: 34px;
    }
  }
}

.icon {
  all: unset;
  width: 32px;
  height: 32px;
  background-color: white;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .count {
    width: 20px;
    height: 20px;
    background-color: #e92243;
    color: #ffffff;
    font-size: 12px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -7px;
    right: -13px;
    font-family: 'roboto medium', sans-serif;
    padding: 12px;
  }
}
