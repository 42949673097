.content {
  width: 100%;
  height: 100%;
  gap: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.reconsiderModal {
    width: 1000px;
  }

  .img {
    width: 88px;
    height: 88px;
    border-radius: 100%;
    border: 1px solid #65A518;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info {
    width: 396px;
    height: 129px;
    gap: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title {
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
    text-align: center;
    color: #65A518;
    font-family: 'hdipro medium', sans-serif;
  }

  .description {
    font-size: 16px;
    font-weight: normal;
    line-height: 25px;
    text-align: center;
    color: #343741;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .children {
    width: 100%;
    gap: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .confirm {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .actions {
    width: 100%;
    gap: 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 1000px) {
  .content.reconsiderModal {
    margin-top: 100%;
    width: 100%;
  }

  .content .img {
    margin-bottom: 10%;
  }
  .content .info {
    width: 100%;
  }
  .content .actions {
    margin-top: 10%;
    flex-direction: column;
  }
}
