@import 'styles/variables';

.status_box_as {
  display: flex;
  border-radius: 2px;
  padding: 2px 10px;
  height: $h-status;
  min-width: $w-status;
  width: max-content;
  padding-block: 3px;
  padding-inline: 8px !important;
  justify-content: center;
  align-items: center;
  font-size: 11px !important;
  color: black;
  margin-block: auto;

  & span {
    padding-inline: 0 !important;
    height: max-content !important;
    font-family: 'roboto bold', sans-serif;
  }
}

.renewal_text {
  color: #03ac63;
}

.vigente_text {
  color: #008040 !important;
}

.vencida_text {
  color: #d32f2f !important;
}

.cancelada_text {
  color: #565656 !important;
}

.anulada_text {
  color: #323232 !important;
}

.prop_cartera_text {
  color: #b85d00 !important;
}

.renewal_box {
  background-color: #03ac63;
}

.vigente_box {
  background-color: #008040 !important;
}

.vencida_box {
  background-color: #d32f2f !important;
}

.cancelada_box {
  background-color: #565656 !important;
}

.anulada_box {
  background-color: #323232 !important;
}

.prop_cartera_box {
  background-color: #b85d00;
}

.prop_suplem_box {
  background-color: #28a3af;
}

.propsuplem_box {
  background-color: #28a3af;
}

.status_box_sin {
  width: max-content;
  border-radius: 3px;
  height: 20.15px;
  display: flex;
  padding-inline: 8px;
  padding-block: 4px !important;
  justify-content: center;
  align-items: center;

  & span {
    font-size: 11px !important;
    height: max-content !important;
  }
}

.inspeccion_box {
  background-color: #dc5f00;
}

.abierto_box,
.emitida_box {
  background-color: #06748c;
}

.eliminada_box {
  background-color: #565656;
}

.pendiente_box,
.reabierto_box,
.renovacion_en_curso_box {
  background-color: #eea600;
}

.modificacion_en_curso_box {
  background-color: #ffbb1c;
  height: 32px;
  width: 88.55px;
}

.cobrado_box {
  background-color: #707070;
}

.remesado_box {
  background-color: #28a3af;
}
.rechazado_box {
  background-color: #e92243;
}

.retenida_box {
  background-color: #d32f2f;
}

.entrada_en_vigencia_futura_box {
  background-color: #008040;
  height: 32px;
  width: 104.56px;
  padding-inline: 4px;
}
.terminado_box {
  background-color: #008040;
}

.managerenewal_box {
  background-color: #28a3af;
  height: 21px;
  & span{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 115px !important;
  }
}

.renovada_box {
  background-color: #03ac63;
}

.sinverif_box {
  background-color: #1a1446;
  & span {
    width: 121px !important;
    max-height: 21px;
    white-space: nowrap;
    overflow: hidden;
  }
}
.prerenovacion_box {
  background-color: #eea600;
  & span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 115px !important;
  }
}
.sinprerenovacion_box {
  background-color: #e92243;
  & span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 115px !important;
  }
}
.otro_box {
  background-color: #ffd000;
  & span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 115px !important;
  }
}
.sinsarlaft_box {
  background-color: #343741;
}
.anulada_box {
  background-color: #d32f2f !important;
}
.cannot_renewed_box {
  background-color: transparent;
}
.renewing_box {
  background-color: #ffd000;
}
.renewalerror_box {
  background-color: #e92243;
}
.actualizar_box {
  background-color: transparent;
}

.text_status {
  color: #ffffff !important;
  font-size: 11px !important;
  font-family: 'roboto bold', sans-serif !important;
}

.text_status_renovations {
  color: #ffffff !important;
  font-size: 11px !important;
  font-family: 'roboto regular', sans-serif !important;
}
