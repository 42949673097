.container {
  background-color: transparent;
  position: relative;
  height: max-content;
  width: max-content;

  & .tooltip {
    top: 100%;
    left: -300%;
    display: none;
    width: 256px;
    height: max-content;
    position: absolute;
    flex-direction: column;
    z-index: 100;

    & .triangle {
      margin-inline: auto;
      width: 0;
      height: 0;
      border-bottom: 10px solid #003960 !important;
      border-left: 12px solid transparent !important;
      border-right: 10px solid transparent !important;
    }

    & .info_tooltip {
      padding: 20px;
      display: flex;
      justify-content: center;
      border-radius: 3px;
      background-color: #003960;
      color: #ffffff;
      font-size: 14px;
      font-family: 'roboto regular', sans-serif;
    }
  }

  &:hover {
    & .tooltip {
      display: flex;
      &:hover {
        display: none;
      }
    }
  }
}
